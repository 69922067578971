import styled from 'styled-components';
import tw from 'tailwind.macro';
import GatsbyImage from 'gatsby-image';

export const Footer = styled.footer`
  ${tw`bg-gray-300 border-t border-gray-200 py-1`};
  border-top-width: 0px;
`;

export const Links = styled.div`
  ${tw`flex items-center justify-center w-full pb-3`};

  a {
    ${tw`text-indigo-900 hover:text-indigo-600 mx-2`};
  }
`;

export const Link = styled.a`
  ${tw`text-indigo-900 hover:text-indigo-600 mx-2 text-xs sm:text-base`};
`;

export const Text = styled.p`
  ${tw`text-indigo-900 hover:text-indigo-600 mx-2`};
`;

export const TextFooter = styled.div`
  ${tw`flex flex-col items-center text-center text-xs w-full`};
`;

export const FooterLinks = styled.div`
  ${tw`flex flex-row justify-center`};
`;

export const FooterLink = styled.span`
  ${tw`mr-3 no-underline hover:underline text-blue-400`};
`;

export const HideOnMobile = styled.span`
@media screen and (max-width: 640px) {
  position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}
`;

export const HideOnDesktop = styled.span`
@media screen and (min-width: 640px) {
  position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}
`;

export const Signature = styled(GatsbyImage)`
  ${tw`w-16`};
`;
