import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from 'components/ui/Container';

import * as Styled from './styles'; 

const Footer = () => {
  const { placeholderImage } = useStaticQuery(graphql`
  query {
    placeholderImage: file(relativePath: { eq: "artisticSignature.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`);

return (
<Styled.Footer>
    <Container>
      <Styled.Links>
        <Styled.Link href="https://github.com/zurvar" rel="noreferrer noopener" target="_blank">
          GitHub
        </Styled.Link>
        <Styled.Link
          href="https://www.linkedin.com/in/zur-vonarburg-shmaria-383ab3197/"
          rel="noreferrer noopener"
          target="_blank"
        >
          LinkedIn
        </Styled.Link>
        <Styled.Link href="https://www.researchgate.net/profile/Zur_Vonarburg-Shmaria" rel="noreferrer noopener" target="_blank">
          ResearchGate
        </Styled.Link>
        <Styled.Link href="https://scholar.google.com/citations?user=7237n8kAAAAJ&hl=en" rel="noreferrer noopener" target="_blank">
          GoogleScholar
        </Styled.Link>
      </Styled.Links>
      <Styled.TextFooter>
      <Styled.Signature fluid={placeholderImage.childImageSharp.fluid} alt="ArtisticSignature" />
        Zur Vonarburg-Shmaria 2023 <br/>
        <Styled.HideOnMobile>About how I taught my computer some more sentences than hello world.</Styled.HideOnMobile>
        <Styled.HideOnDesktop>Coding + Theory + Design = happiness</Styled.HideOnDesktop>
        <Styled.FooterLinks>
          <Styled.FooterLink><Link to="/sitemap.xml">sitemap</Link></Styled.FooterLink>   
          <Styled.FooterLink><Link to="/robots.txt">robots</Link></Styled.FooterLink>   
          <Styled.FooterLink><Link to="/humans.txt">humans</Link></Styled.FooterLink>   
        </Styled.FooterLinks>
      </Styled.TextFooter>
    </Container>
  </Styled.Footer>
  );
};

export default Footer;
